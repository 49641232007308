import React from "react"
import Input from "../components/input"

import styles from "./panel.module.css"

export default ({ title, filters, filterBy, type, handler, reset }) => {
  return (
    <div className={styles.block}>
      <h3 className={styles.title}>{title}</h3>
      {filters &&
        filters.map((filter, i) => (
          <Input
            key={i}
            type={type}
            handler={handler}
            filter={filter}
            reset={reset}
          />
        ))}
    </div>
  )
}
