import React from "react"
import Tag from "./tag"
import styles from "./card.module.css"

export default ({
  businessName,
  donationLink,
  giftCard,
  website,
  instagram,
  businessFocus,
  state,
  serviceCities,
}) => (
  <div className={styles.card}>
    <div className={styles.top}>
      <h2 className={styles.title}>
        <a href={website}>{businessName}</a>
      </h2>
      <p>
        {businessFocus} | {state}
      </p>
      <div>
        <div className={styles.services}>Servicing</div>
        {serviceCities.split(",").map((city, i) => (
          <Tag text={city} key={i} />
        ))}
      </div>
    </div>

    <div className={styles.actions}>
      <div className={styles.body}>
        <div className={styles.links}>
          <a className={`${styles.link}`} href={website}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="19"
              height="19"
            >
              <defs>
                <filter
                  id="a"
                  width="115%"
                  height="114.6%"
                  x="-7.5%"
                  y="-4.1%"
                  filterUnits="objectBoundingBox"
                >
                  <feOffset
                    dy="12"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                  ></feOffset>
                  <feGaussianBlur
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                    stdDeviation="7"
                  ></feGaussianBlur>
                  <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0664881993 0"
                  ></feColorMatrix>
                </filter>
                <path id="b" d="M0 0h360v369H0z"></path>
              </defs>
              <g fill="none" fillRule="evenodd">
                <g transform="translate(-44 -276)">
                  <use fill="#000" filter="url(#a)" xlinkHref="#b"></use>
                  <use fill="#FFF" xlinkHref="#b"></use>
                </g>
                <path
                  fill="#FF7676"
                  fillRule="nonzero"
                  d="M9.5 0C4.26 0 0 4.26 0 9.5S4.26 19 9.5 19 19 14.74 19 9.5 14.74 0 9.5 0zm2.04 4.94c-.66.12-1.34.18-2.04.18s-1.38-.06-2.04-.18c.52-2.36 1.38-3.76 2.04-3.76s1.52 1.4 2.04 3.76zm-.02-3.5c1.44.36 2.74 1.1 3.78 2.12-.76.48-1.64.86-2.6 1.12-.28-1.28-.68-2.4-1.18-3.24zM7.02 8.9c.02-1.02.1-1.96.24-2.8.74.12 1.48.2 2.26.2.76 0 1.52-.08 2.26-.2.12.84.2 1.78.24 2.8h-5zm4.96 1.2c-.02 1.02-.1 1.96-.22 2.8-.74-.12-1.48-.2-2.26-.2-.761 0-1.521.08-2.261.2-.12-.84-.2-1.78-.22-2.8h4.96zM6.3 4.68c-.96-.26-1.84-.64-2.6-1.12 1.04-1.021 2.34-1.74 3.78-2.121-.5.84-.9 1.96-1.18 3.24zm-.2 1.18c-.16.98-.24 2.02-.26 3.06h-4.6c.12-1.66.74-3.2 1.7-4.46.88.6 1.98 1.08 3.16 1.4zm-.28 4.24c.02 1.04.12 2.06.26 3.06-1.18.32-2.26.78-3.18 1.4a8.33 8.33 0 01-1.68-4.46h4.6zm.48 4.22c.28 1.28.68 2.42 1.18 3.24a8.259 8.259 0 01-3.78-2.12c.76-.48 1.64-.86 2.6-1.12zm1.16-.26c.66-.12 1.34-.18 2.04-.18s1.38.06 2.04.18c-.52 2.36-1.38 3.76-2.04 3.76s-1.52-1.4-2.04-3.76zm5.24.26c.96.26 1.84.64 2.6 1.14-1.04 1.02-2.34 1.74-3.78 2.12.5-.86.9-1.98 1.18-3.26zm.2-1.18c.16-.98.24-2.02.26-3.06h4.6c-.12 1.66-.74 3.2-1.7 4.46-.9-.6-1.98-1.08-3.16-1.4zm.28-4.24c-.02-1.04-.12-2.06-.26-3.06 1.18-.32 2.26-.78 3.18-1.4a8.3 8.3 0 011.7 4.46h-4.62z"
                ></path>
              </g>
            </svg>
            <span>Website</span>
          </a>
          <a className={`${styles.link} ${styles.ig}`} href={instagram}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="19"
              height="19"
            >
              <defs>
                <filter
                  id="a"
                  width="115%"
                  height="114.6%"
                  x="-7.5%"
                  y="-4.1%"
                  filterUnits="objectBoundingBox"
                >
                  <feOffset
                    dy="12"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                  ></feOffset>
                  <feGaussianBlur
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                    stdDeviation="7"
                  ></feGaussianBlur>
                  <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0664881993 0"
                  ></feColorMatrix>
                </filter>
                <path id="b" d="M0 0h360v369H0z"></path>
              </defs>
              <g fill="none" fillRule="evenodd">
                <g transform="translate(-211 -276)">
                  <use fill="#000" filter="url(#a)" xlinkHref="#b"></use>
                  <use fill="#FFF" xlinkHref="#b"></use>
                </g>
                <path
                  fill="#FF7676"
                  fillRule="nonzero"
                  d="M13.48 0A5.525 5.525 0 0119 5.52v7.96A5.525 5.525 0 0113.48 19H5.52A5.525 5.525 0 010 13.48V5.52A5.525 5.525 0 015.52 0zm0 1.484H5.52A4.04 4.04 0 001.483 5.52v7.962a4.04 4.04 0 004.035 4.035h7.962a4.04 4.04 0 004.035-4.035V5.519a4.04 4.04 0 00-4.035-4.035zM9.5 4.471A5.035 5.035 0 0114.53 9.5c0 2.773-2.257 5.03-5.03 5.03S4.47 12.272 4.47 9.5 6.728 4.47 9.5 4.47zm0 1.457A3.576 3.576 0 005.928 9.5 3.576 3.576 0 009.5 13.072 3.576 3.576 0 0013.072 9.5 3.576 3.576 0 009.5 5.928zm5.309-2.575a.838.838 0 110 1.676.838.838 0 010-1.676z"
                ></path>
              </g>
            </svg>

            <span>Instagram</span>
          </a>
        </div>
      </div>

      <div className={styles.group}>
        <a className={styles.btn} href={donationLink}>
          Donate
        </a>
        <a className={styles.btn} href={giftCard}>
          Giftcard
        </a>
      </div>
    </div>
  </div>
)
