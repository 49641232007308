import React from "react"

import styles from "./input.module.css"

export default ({ filter, type, handler }) => {
  return (
    <label className={styles.block}>
      <input
        className={styles.input}
        type="checkbox"
        name={type}
        value={filter}
        onClick={() => handler(type, filter)}
      />
      <span className={styles.visual}></span>
      <span className={styles.label}>{filter}</span>
    </label>
  )
}
