import React, { useEffect, useRef } from "react"

export default ({ reset, children }) => {
  const form = useRef()

  useEffect(() => {
    form.current.reset()
  }, [reset])

  return <form ref={form}>{children}</form>
}
