import React from "react"

import styles from "./resetButton.module.css"

export default ({ label, handler }) => {
  return (
    <div className={styles.block}>
      <input
        className={styles.btn}
        type="button"
        onClick={handler}
        value="Reset"
      />
    </div>
  )
}
