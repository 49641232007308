import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import Panel from "../components/panel"
import ResetButton from "../components/resetButton"
import Form from "../components/form"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query Spreadsheet {
      allGoogleSpreadsheetFormResponses1 {
        edges {
          node {
            id
            instagram
            website
            emailAddress
            businessFocus
            businessName
            donationLink
            giftCard
            serviceCities
            state
          }
        }
      }
    }
  `)

  const [initial] = useState(data.allGoogleSpreadsheetFormResponses1.edges)
  const [listings, setListing] = useState([])
  const [states, setStates] = useState([])
  const [types, setTypes] = useState([])
  const initFilters = {
    state: [],
    businessFocus: [],
  }
  const [currentFilters, setCurrentFilters] = useState(initFilters)
  const [reset, setReset] = useState(false)

  useEffect(() => {
    const states = initial.map(d => d.node.state)
    const types = initial.map(d => d.node.businessFocus)
    setStates([...new Set(states)])
    setTypes([...new Set(types)])
  }, [initial])

  const updateFilters = (type, term) => {
    if (currentFilters[type].some(el => el === term)) {
      setCurrentFilters(prevState => {
        const terms = currentFilters[type].filter(el => el !== term)
        return { ...prevState, [type]: terms }
      })
    } else {
      setCurrentFilters(prevState => {
        const terms = currentFilters[type].concat(term)
        return { ...prevState, [type]: terms }
      })
    }
  }

  useEffect(() => {
    if (!currentFilters.state.length && !currentFilters.businessFocus.length) {
      setListing(initial)
    } else {
      const filtered = initial.filter(listing => {
        if (
          currentFilters.state.length &&
          currentFilters.businessFocus.length
        ) {
          return (
            currentFilters.state.includes(listing.node.state) &&
            currentFilters.businessFocus.includes(listing.node.businessFocus)
          )
        } else {
          return (
            currentFilters.state.includes(listing.node.state) ||
            currentFilters.businessFocus.includes(listing.node.businessFocus)
          )
        }
      })
      setListing(filtered)
    }
  }, [currentFilters])

  const resetList = () => {
    setListing(initial)
    setReset(!reset)
    setCurrentFilters(initFilters)
  }

  return (
    <Layout>
      <SEO title="Home" />
      <aside className="aside">
        <Form reset={reset}>
          <Panel
            title="State"
            filters={states}
            type="state"
            filterBy="state"
            handler={updateFilters}
          />

          <Panel
            title="Business Type"
            filters={types}
            type="businessFocus"
            filterBy="businessFocus"
            handler={updateFilters}
          />
          <ResetButton label="Reset" handler={resetList} />
        </Form>
      </aside>
      <div className="main">
        <div className="grid">
          {listings.length
            ? listings.map(row => <Card key={row.node.id} {...row.node} />)
            : `No results found`}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
